<template>
  <div class="content">
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="agreement" v-html="html">
    </div>
  </div>
</template>

<script>
import { getAgreement } from '@/api/regInfo.js'
import heads from '@/compontens/heads.vue'
import tool from '@/utils/tools.js'
import Cookies from 'js-cookie'

export default {
  components: { heads },
  data() {
    return {
      title_msg: '协议',
      showMore: true,
      html: '',
      type: this.$route.query.type || '4',
      zkb_pro: this.$route.query.zkb_pro || '',
      isZkb: false
    }
  },
  created() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    const isZk = location.host.indexOf('zk')
    if (Cookies.get('agency_id') == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.isZkb = true
      this.zkb_pro = '1'
    } else {
      this.isZkb = false
      this.zkb_pro = ''
    }

    if (tool.isInApp()) {
      if (tool.isInIosApp()) {
        if (this.type == '4') {
          this.title_msg = '用户协议'
        } else if (this.type == '5') {
          this.title_msg = '隐私政策'
        }
      }
    }
    // 请求数据
    this.getAgreement()
  },
  methods: {
    getAgreement() {
      getAgreement({ type: this.type, zkb_pro: this.zkb_pro }).then((res) => {
        console.log(res)
        const errNo = res.errNo
        if (errNo == 0) {
          const result = res.result || {}
          this.html = result.agreement || ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.agreement {
  padding: 40px;
  color: #333 !important;
  font-size: 28px !important;
  overflow-x: hidden;
  p{
    span{
      white-space:normal !important;
    }
  }
}

/deep/img {
  max-width: 100% !important;
}
</style>
